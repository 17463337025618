<template>
  <div class="main">
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane
        v-for="(item, index) in tabList"
        :key="item.key"
        :label="item.label"
        :name="item.key">
      </el-tab-pane>
    </el-tabs>
    <!-- 搜索栏 -->
    <Search
      ref="search"
      :activeName="activeName"
      @onCreate="onCreate"
      @getList="getList"
      @onExport="exportExcel" />
    <!-- 表格 -->
    <!-- 授权分配表格 -->
    <AssignTable v-if="activeName === 1" ref="AssignTableRef" @reissue="reissue" />
    <custem-table
      v-else
      :tableInfo="tableInfo"
      :tableLoading="tableLoading"
      @getList="getList" />
    <!-- 授权分配详情 -->
    <assign-detail ref="assignDetail" />
    <!-- 授权管理平台详情 -->
    <apply-detail ref="applyDetail" @getList="getList" @closeApplication="closeApplication" />
    <!-- 授权分配详情- 下发授权 -->
    <auth-appli ref="auth_appli" @getList="getList" />
  </div>
</template>

<script setup>
import { ref, inject, computed, getCurrentInstance, reactive, nextTick, watch } from 'vue'
import { AuthManagement } from '@/utils/api'
import AssignDetail from '../Assignment/components/Detail.vue'
import AuthAppli from '../Assignment/components/Form.vue'
import Search from './components/ListToolBar.vue'
import ApplyDetail from '../Application/components/Detail.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import AssignTable from '@/views/Authorization/Assignment/components/table.vue'
import store from '@/store'
const t = inject('t')
const search = ref()
const activeName = ref(1)
const { proxy } = getCurrentInstance()
const tableLoading = ref(false) // 表格loading
const searchData = ref()
const assignDetail = ref() // 授权分配详情
const applyDetail = ref() // 授权申请详情
const auth_appli = ref()
const AssignTableRef = ref()
const tabList = ref([
  { // 创建 & 分配
    label: t('assign.create_assign'),
    key: 1
  },
  { // 审核 & 分配
    label: t('assign.check_assign'),
    key: 2
  },
  { // 授权统计
    label: t('assign.license_statistics'),
    key: 3
  }
])
const sourceList = reactive({
  0: t('assign.scan_code'),
  1: t('assign.sub_user_apply'),
  2: t('assign.admin_create'),
  3: t('assign.third_platform')
})
// 获取申请列表
const getApplyList = async () => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.ApplicationList({
      ...searchData.value
    })
    applyTableInfo.data = res.items
    applyTableInfo.totalPage = res.total
    applyTableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}
// 获取统计列表
const getAccountList = async () => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.AllocationAssignmentCount({
      ...searchData.value
    })
    countTableInfo.data = res.items
    countTableInfo.totalPage = res.total
    countTableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 获取列表
const getList = async (search = {}) => {
  searchData.value = search
  switch (activeName.value) {
    case 1: {
      await nextTick()
      AssignTableRef.value.getList(search)
      break;
    }
    case 2:
      getApplyList()
      break;
    case 3:
      getAccountList()
      break;
    default:
      break;
  }
}
// 企业创建授权
const onCreate = () => {
  if (!!!auth_appli.value) return
  auth_appli.value.openWin()
}
// 授权详情
const assignDetial = (data) => {
  if (!!!assignDetail.value) return
  assignDetail.value.openWin(data, false)
}
// 重新下发授权
const reissue = (data) => {
  if (!!!auth_appli.value) return
  auth_appli.value.openWin(data)
}
// 导出表格
const exportExcel = () => {
  let type = activeName.value === 2 ? 'allocation' : activeName.value === 1 ? 'assignment' : 'allocation_assignment'
  window.open(`${process.env.VUE_APP_API_URI}v1/allocation_assignment/csv?type=${type.trim()}&api_key=${store.state.authorization}`)
}

// 扫码详情
const openDetailPlat = (data) => {
  if (!!!applyDetail.value) return
  applyDetail.value.openWin(data)
}
// 分配
const confirmAss = (data) => {
  openDetailPlat({
    ...data,
    openType: 'confirm'
  })
}
// 撤销申请
const closeApplication = (data) => {
  let status = 8, statusName = t('form.revoke')
  if (!!!proxy.$userRole(['Subordinate'])) {
    status = 9
    statusName = t('form.reject')
  }
  ElMessageBox.prompt(t('user.notes'), `${statusName} ${t('assign.apply')}`, {
    confirmButtonText: t('form.submit'),
    cancelButtonText: t('form.cancel'),
    inputPlaceholder: t('user.notes')
  })
    .then(async ({ value }) => {
      const res = await changeState(data.id, {
        status: status,
        comment: value
      })
      if (res) {
        ElMessage.success(`${statusName} ${t('assign.success')}`)
        detail_win.value.closeWin()
        getList()
      }
    }).catch()
}
// 授权统计详情
const countDetail = (data) => {
  if (data.type === 'assignment') {
    assignDetial(data)
  } else {
    openDetailPlat(data)
  }
}
// 申请表格
const applyTableInfo = reactive({
  header: [
    { // 申请创建时间
      key: 'created_at',
      label: t('assign.application_creation_time'),
      minWidth: '252px',
      sortable: true
    },
    { // 申请编号
      key: 'id',
      label: t('assign.application_id'),
      minWidth: '320px',
      sortable: true
    },
    { // 申请来源
      key: 'source',
      label: t('assign.application_source'),
      minWidth: '300px',
      custem: (data) => {
        return sourceList[data.source] || '-'
      }
    },
    { // 项目
      key: 'project_name',
      label: t('dashboard.project'),
      minWidth: '300px',
      custem: (data) => {
        return data.project ? data.project.name : '-'
      }
    },
    { // 产品名称
      key: 'product_name',
      label: t('product.product_name'),
      minWidth: '260px',
      sortable: true,
      sortBy: (data) => {
        return data.project.product.name
      },
      custem: (data) => {
        return data.project ? (data.project.product ? data.project.product.name : '-') : '-'
      }
    },
    { // 申请数量
      key: 'content.hosts',
      label: t('assign.application_count'),
      sortable: true,
      sortBy: (data) => {
        return data.content.hosts
      },
      minWidth: '180px',
      custem: (data) => {
        return data.content ? `${proxy.$numberToCurrencyNo(data.content.hosts)} ${t('unit.ge')}` : '-'
      }
    },
    { // 审核状态
      key: 'status',
      label: t('enterprise.review_status'),
      minWidth: '110px',
      hidden: true,
      custem: (data) => {
        return proxy.$formatApplicatState(data.status, 'html')
      }
    },
    {
      label: t('table.action'),
      width: '140px',
      fixed: 'right',
      handleFun: [
        {
          name: t('assign.assign'),
          hidden: () => {
            return !!!proxy.$userRole(['Enterprise'])
          },
          fn: confirmAss,
          disabled: (data) => {
            return data.status !== 0
          }
        },
        {
          name: t('table.detail'),
          fn: openDetailPlat
        }
      ]
    }
  ]
})
// 授权统计表格
const countTableInfo = reactive({
  header: [
    { // 创建时间
      key: 'created_at',
      label: t('assign.application_creation_time'),
      sortable: true,
      minWidth: '240px'
    },
    { // 编号
      key: 'id',
      label: t('system.id'),
      sortable: true,
      minWidth: '200px'
    },
    { // 申请来源
      key: 'source',
      label: t('assign.application_source'),
      minWidth: '160px',
      custem: (data) => {
        return sourceList[data.source]
      }
    },
    { // 所属项目
      key: 'project_name',
      label: t('assign.project'),
      minWidth: '174px'
    },
    { // 产品名称
      key: 'product_name',
      label: t('product.product_name'),
      sortable: true,
      minWidth: '200px'
    },
    { // 订单编号
      key: 'order_code',
      sortable: true,
      label: t('order.order_code'),
      minWidth: '200px'
    },
    { // 订单类别
      key: 'order_id',
      label: t('order.order_type'),
      minWidth: '140px',
      sortable: true,
      sortBy: (data) => {
        return data.order_category_id
      },
      custem: (data) => {
        return data.order_category_id ? proxy.$formatType(data.order_category_id, data.order_params.service_type === 'failback' ? t('order.failback') : '') : '-'
      }
    },
    { // 申请数量
      key: 'content.hosts',
      label: t('assign.application_count'),
      minWidth: '180px',
      sortBy: (data) => {
        return data.content.hosts
      },
      sortable: true,
      custem: (data) => {
        return data.content ? `${proxy.$numberToCurrencyNo(data.content.hosts)} ${t('unit.ge')}` : '-'
      }
    },
    { // 使用周期
      key: 'use_period',
      label: t('order.order_cycle'),
      sortable: true,
      minWidth: '140px',
      sortBy: (data) => {
        return data.use_period
      },
      custem: (data) => {
        return data.use_period ? `${proxy.$numberToCurrencyNo(data.use_period)} ${Number(data.use_period) > 1 ? t('system.days') :t('system.day')}` : '-'
      }
    },
    { // 授权生效时间
      key: 'start_at',
      label: t('assign.license_start_at'),
      minWidth: '220px',
      sortable: true,
      sortBy: (data) => {
        return data.start_at
      }
    },
    { // 使用到期时间
      key: 'expired_at',
      label: t('order.expiration_time'),
      sortable: true,
      minWidth: '180px'
    },
    {
      key: 'status',
      label: t('enterprise.review_status'),
      minWidth: '160px',
      sortable: true,
      custem: (data) => {
        return proxy.$formatApplicatState(data.status, 'html')
      }
    },
    {
      label: t('table.action'),
      width: '90px',
      fixed: 'right',
      handleFun: [
        {
          name: t('table.detail'),
          fn: countDetail
        }
      ]
    }
  ]
})
const tableInfo = computed(() => {
  return activeName.value === 2 ? applyTableInfo : countTableInfo
})

watch(() => activeName.value, async (val) => {
  getList()
  await nextTick()
  search.value.searchData = {
    page: 1
  }
}, { deep: true, immediate: true })
</script>

<style lang="scss" scoped>
.main {
  padding-top: 0 !important;
}
:deep(.table_wrap) {
  margin-top: 16px !important;
  height: calc(100% - 100px) !important;
}
:deep(.table) {
  height: calc(100% - 54px) !important;
}
</style>